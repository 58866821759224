import UploadRoundedIcon from '@mui/icons-material/UploadRounded'
import { styled, Tooltip, Typography, useTheme } from '@mui/material'
import { ChangeEvent, useEffect, useRef, useState } from 'react'
import { useSkimmerContext } from '../../../context/SkimmerContext'
import { skimmerExcelImportEndpoint } from '../../../endpoints'
import { useChatDocumentUpload } from '../../../hooks/useDocumentUpload'
import { usePost } from '../../../hooks/usePost'
import AddIcon from '../../../icons/AddIcon'
import { UUID, isUUID } from '../../../types'
import { validateSkimmerImportFile } from '../../../utils/documents'
import { generateId } from '../../../utils/objectUtils'
import Modal from '../../common/Modal'
import TransparentButton from '../../common/TransparentButton'
import UnstyledButton from '../../common/UnstyledButton'
import { thickScrollbarStyle, zIndexes } from '../../common/styles'
import PromptLibraryModal from '../../prompt-library/PromptLibraryModal'
import CollapsedPromptCell from './CollapsedPromptCell'
import DocumentCell from './DocumentCell'
import DocumentHeading from './DocumentHeading'
import OrderingButtons from './OrderingButtons'
import PromptCell from './PromptCell'
import PromptHeading from './PromptHeading'
import Upload from './Upload'
import { Layout, estimateDocumentColumnWidths, getLayout, getWidthFromLayout, getWidthKey, trySaveLayout } from '../utils/layoutStorage'
import { SkimmerReport } from '../utils/types'
import { documentResultIsFiltered } from '../utils/utils'
import { tableTransitions } from '../utils/styles'
import { forwardPropsWithTransient } from '../../../utils/muiUtils'

const TableWrapper = styled('div')(() => ({
    width: '100%',
    paddingBottom: '16px',
    overflow: 'auto',
    ...thickScrollbarStyle,
}))

const Table = styled('table')(({ theme }) => ({
    width: 'max-content',
    borderSpacing: '0',

    'th, td': {
        border: `1px solid ${theme.palette.divider}`,
        backgroundColor: theme.palette.background.default,
        ...tableTransitions,
    },

    th: {
        padding: '15px 8px',
        textAlign: 'left',
        backgroundColor: theme.palette.secondary.light,
        position: 'relative',
    },
    'th:nth-of-type(2), td:nth-of-type(2)': {
        position: 'sticky',
        left: '0px',
        maxWidth: '500px',
    },

    // first column is for re-ordering stuff
    'th:first-of-type': {
        backgroundColor: theme.palette.background.default,
        border: 'none',
    },

    'tr td:first-of-type': {
        border: 'none',
    },

    // rounded borders
    'th:nth-of-type(2)': {
        zIndex: zIndexes.navigation,
        borderTopLeftRadius: '12px',
    },
    'th:last-of-type': {
        width: '150px',
        borderTopRightRadius: '12px',
    },
    'tr:last-of-type td:nth-of-type(2)': {
        borderBottomLeftRadius: '12px',
    },
    'tr:last-of-type td:last-of-type': {
        borderBottomRightRadius: '12px',
    },
}))

const StyledTableRow = styled('tr', { shouldForwardProp: forwardPropsWithTransient })<{ $highlighted: boolean }>(({ $highlighted, theme }) => ({
    td: {
        borderColor: $highlighted ? theme.palette.primary.main : theme.palette.divider,
    },
    'td:first-of-type': {
        opacity: $highlighted ? 1 : 0,
    },
    '&:hover td:first-of-type': {
        opacity: 1,
    },
}))

const TableHead = styled('thead', { shouldForwardProp: forwardPropsWithTransient })<{ $zIndex: number }>(({ $zIndex }) => ({
    position: 'sticky',
    top: 0,
    zIndex: $zIndex,
}))

const PromptCellWrapper = styled(PromptCell)<{ $zIndex: number }>(({ $zIndex }) => ({
    zIndex: $zIndex, // Ensure dropdown for each cell can overlay the cell below it
}))

const ControlsCell = styled('td')({
    padding: '15px',
})

const ControlsContainer = styled('span')({
    display: 'inline-flex',
})

const AddDocumentButton = styled(UnstyledButton)({
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    width: '100%',
})

const HiddenInput = styled('input')({
    display: 'none',
})

const UploadModal = styled(Modal)({
    textAlign: 'center',
    height: '85dvh',
    width: '600px',
})

const ButtonText = styled(Typography, { shouldForwardProp: forwardPropsWithTransient })<{ $disabled: boolean }>(({ theme, $disabled }) => ({
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: $disabled ? theme.palette.text.disabled : theme.palette.text.secondary,
}))

const OrderingColHeader = styled('th')({
    maxWidth: '24px',
    padding: '0px',
})

interface SkimmerTableProps {
    report: SkimmerReport
    running: boolean
    locked: boolean
}

const SkimmerTable = ({ report, running, locked: lockRequested }: SkimmerTableProps) => {
    const { palette } = useTheme()
    const { addPrompt, editPrompt, deletePrompt, changeOrder, changeResponseType, changeFilter, deleteDocument, saveAndRefresh } = useSkimmerContext()

    const promptIds = report.results.map(p => p.id)

    const [showUploadModal, setShowUploadModal] = useState(false)
    const [showPromptLibrary, setShowPromptLibrary] = useState<boolean | UUID>(false)
    const [selectedRowIds, setSelectedRowIds] = useState(promptIds)
    const [layout, setLayout] = useState(getLayout(report.chatId))
    const [highlightedRowId, setHighlightedRowId] = useState<UUID | null>(null)

    const movedRowRef = useRef<HTMLElement | null>(null)
    const hiddenInputRef = useRef<HTMLInputElement>(null)

    const [uploadFile, uploadLoading] = useChatDocumentUpload(report.chatId)
    const [triggerImport, importLoading] = usePost<UUID, boolean>(skimmerExcelImportEndpoint(report.chatId))

    const locked = lockRequested || uploadLoading || importLoading

    useEffect(() => {
        if (highlightedRowId && movedRowRef.current) {
            movedRowRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' })
            setTimeout(() => {
                setHighlightedRowId(null)
                movedRowRef.current = null
            }, 2500)
        }
    }, [highlightedRowId])

    const handleImport = async ({ target: { files } }: ChangeEvent<HTMLInputElement>) => {
        const file = validateSkimmerImportFile(files)

        if (file) {
            const uploadResult = await uploadFile([{ id: generateId(), file }])

            if (uploadResult.uploadedIds.length) {
                const importResult = await triggerImport(uploadResult.uploadedIds[0])

                return importResult
            }
        }
        return false
    }

    const handleToggleAll = () => !locked && setSelectedRowIds(selectedRowIds.length === report.results.length ? [] : promptIds)

    const handleToggle = (id: UUID) =>
        !locked &&
        setSelectedRowIds(selectedRowIds.includes(id) ? selectedRowIds.filter(alreadySelectedId => alreadySelectedId !== id) : selectedRowIds.concat(id))

    const handleDocumentColumnToggle = (documentId: UUID) => {
        const updatedLayout: Layout = {
            ...layout,
            collapsed: layout.collapsed.includes(documentId) ? layout.collapsed.filter(id => id !== documentId) : [...layout.collapsed, documentId],
        }
        setLayout(updatedLayout)
        trySaveLayout(report.chatId, updatedLayout)
    }

    const handlePromptColumnToggle = () => {
        const updatedLayout: Layout = { ...layout, promptCollapsed: !layout.promptCollapsed }
        setLayout(updatedLayout)
        trySaveLayout(report.chatId, updatedLayout)
    }

    const handleDocumentColumnWidthChange = (documentId: UUID) => (controlledWidth: number | null) => {
        const updatedLayout: Layout = { ...layout }
        if (controlledWidth) {
            updatedLayout[getWidthKey(documentId)] = controlledWidth
        } else {
            delete updatedLayout[getWidthKey(documentId)]
        }
        setLayout(updatedLayout)
        trySaveLayout(report.chatId, updatedLayout)
    }

    const handleOrderChange = (id: UUID, direction: 'up' | 'down', target: HTMLElement) => {
        if (!locked) {
            changeOrder(id)(direction)
            setHighlightedRowId(id)
            movedRowRef.current = target
        }
    }

    const placeholderCells = Array(report.documents.length + 1) // +1 for the empty add document column
        .fill('')
        .map((_, i) => <td key={i} />)

    const widthEstimates = estimateDocumentColumnWidths(report.documents, report.results)
    const getWidth = getWidthFromLayout(layout)

    return (
        <TableWrapper>
            <Table>
                <TableHead $zIndex={report.results.length + 1}>
                    <tr>
                        <OrderingColHeader />
                        <th>
                            <PromptHeading
                                allSelected={selectedRowIds.length === report.results.length}
                                onToggleAll={handleToggleAll}
                                collapsed={layout.promptCollapsed}
                                onCollapse={handlePromptColumnToggle}
                            />
                        </th>
                        {report.documents.map(doc => (
                            <DocumentHeading
                                key={doc.id}
                                id={doc.id}
                                name={doc.name}
                                locked={locked}
                                onDelete={() => deleteDocument(doc.id)}
                                collapsed={layout.collapsed.includes(doc.id)}
                                onCollapse={() => handleDocumentColumnToggle(doc.id)}
                                width={getWidth(doc.id) ?? widthEstimates[doc.id]}
                                onWidthChange={handleDocumentColumnWidthChange(doc.id)}
                            />
                        ))}
                        <th>
                            <AddDocumentButton role='button' aria-label='add document' disabled={locked} onClick={() => setShowUploadModal(true)}>
                                <AddIcon color={locked ? palette.text.disabled : palette.text.secondary} />
                                <ButtonText $disabled={locked}>Add Document</ButtonText>
                            </AddDocumentButton>
                        </th>
                    </tr>
                </TableHead>
                <tbody>
                    {report.results.map((promptResult, i) => (
                        <StyledTableRow key={promptResult.id} $highlighted={highlightedRowId === promptResult.id}>
                            <OrderingButtons
                                promptResultId={promptResult.id}
                                onOrderChange={handleOrderChange}
                                isLastRow={i === report.results.length - 1}
                                isFirstRow={i === 0}
                                isHighlighted={highlightedRowId === promptResult.id}
                            />
                            {!layout.promptCollapsed ? (
                                <PromptCellWrapper
                                    $zIndex={report.results.length - i}
                                    promptResult={promptResult}
                                    locked={locked}
                                    selected={selectedRowIds.includes(promptResult.id)}
                                    onToggle={() => handleToggle(promptResult.id)}
                                    onEdit={editPrompt(promptResult.id)}
                                    onReplace={() => setShowPromptLibrary(promptResult.id)}
                                    onDelete={() => deletePrompt(promptResult.id)}
                                    onResponseTypeChange={changeResponseType(promptResult.id)}
                                    onFilterChange={changeFilter(promptResult.id)}
                                />
                            ) : (
                                <CollapsedPromptCell
                                    promptResult={promptResult}
                                    selected={selectedRowIds.includes(promptResult.id)}
                                    onToggle={() => handleToggle(promptResult.id)}
                                />
                            )}
                            {promptResult.results.map(docResult => (
                                <DocumentCell
                                    key={docResult.documentId}
                                    documentResult={docResult}
                                    running={running}
                                    digestMatch={promptResult.digest === docResult.promptDigest}
                                    filtered={promptResult.filterValue === null ? null : documentResultIsFiltered(promptResult, docResult)}
                                    collapsed={layout.collapsed.includes(docResult.documentId)}
                                    width={getWidth(docResult.documentId) ?? widthEstimates[docResult.documentId]}
                                />
                            ))}
                            <td />
                        </StyledTableRow>
                    ))}
                    <tr>
                        <td />
                        <ControlsCell>
                            <ControlsContainer>
                                <TransparentButton type='button' aria-label='add prompt' disabled={locked} onClick={() => addPrompt()}>
                                    <AddIcon color={locked ? palette.text.disabled : palette.text.secondary} />
                                    {!layout.promptCollapsed && <ButtonText $disabled={locked}>New Prompt</ButtonText>}
                                </TransparentButton>
                                <TransparentButton
                                    type='button'
                                    aria-label='import from excel'
                                    onClick={() => hiddenInputRef.current?.click()}
                                    disabled={locked}
                                >
                                    <UploadRoundedIcon sx={{ color: locked ? palette.text.disabled : palette.text.secondary }} width={20} height={18} />
                                    {!layout.promptCollapsed && (
                                        <Tooltip title='Import prompts from another Table. You can use the "Export to Excel" button in the top panel to obtain a file.'>
                                            <ButtonText $disabled={locked}>Import from Excel</ButtonText>
                                        </Tooltip>
                                    )}
                                    <HiddenInput ref={hiddenInputRef} type='file' onChange={event => saveAndRefresh(() => handleImport(event))} />
                                </TransparentButton>
                            </ControlsContainer>
                        </ControlsCell>
                        {placeholderCells}
                    </tr>
                </tbody>
            </Table>
            <UploadModal show={showUploadModal} onClose={() => setShowUploadModal(false)}>
                <Typography variant='h1'>Upload new document</Typography>
                <Upload
                    hideHeader
                    updateMode
                    onChange={callback => {
                        saveAndRefresh(callback)
                        setShowUploadModal(false)
                    }}
                />
            </UploadModal>
            <PromptLibraryModal
                show={!!showPromptLibrary}
                onClose={() => setShowPromptLibrary(false)}
                onSetInput={isUUID(showPromptLibrary) ? editPrompt(showPromptLibrary) : addPrompt}
            />
        </TableWrapper>
    )
}

export default SkimmerTable
