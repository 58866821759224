export type UUID = `${string}-${string}-${string}-${string}-${string}`

const uuidRegex = /^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$/
export const isUUID = (value: unknown): value is UUID => typeof value === 'string' && uuidRegex.test(value)

export interface ObjectWithId {
    id: UUID
}

export type Url = `${'https' | 'http'}://${string}`

export type Dollar = number

export type MessageType = 'normal' | 'error' | 'warning' | 'boilerplate' | 'collapsed'
export type ChatStatus = 'active' | 'archived' | 'locked'
export type DocumentState = 'uploading' | 'waiting' | 'uploaded' | 'processing' | 'extracted' | 'error'
export enum UserRole {
    User,
    Maintainer,
    AccountManager,
}
export enum PromptLibraryRole {
    Viewer,
    Editor,
    Owner,
}

export const isDownloadableState = (state?: DocumentState) => state === 'waiting' || state === 'uploaded' || state === 'processing' || state === 'extracted'
export const isLoadingState = (state: DocumentState) => state === 'uploading' || state === 'waiting' || state === 'processing'

export type SupportedDocumentFormat = 'pdf'
export type DocumentName = `${string}.${SupportedDocumentFormat}`

// This is used by the frontend to safely handle File[] and link the original File object with the created Document object
// We don't extend File so we can avoid manipulating the complex file object directly
export interface FileWithMetadata {
    id: UUID
    folderName?: string
    file: File
}

const isFileWithMetadata = (value: unknown): value is FileWithMetadata => value !== null && typeof value === 'object' && 'id' in value && 'file' in value
export const isFileWithMetadataArray = (value: unknown): value is FileWithMetadata[] => Array.isArray(value) && value.every(isFileWithMetadata)

export interface Document {
    id: UUID
    name: string
    state: DocumentState
    error?: string
}

export interface SourceDocument extends Document {
    finishedEmbedding: boolean
}

export interface User {
    id: UUID
    tenantId: UUID
    name: string
    email: string
    role: UserRole
    quota: number
    costThisWeek: number
    isActive: boolean
}

export interface Issue {
    id: UUID
    issue: string
}

export type BotStatus =
    // NotConfigured & Preview should never be seen in the frontend, but for completeness they are included here
    | 'not_configured' // newly created bots land in this state
    | 'preview' // the bot has been configured; it can be talked to, but does not display in the new chat menu (so I can start new chat using the API docs, but not in the UI)
    | 'live' // the bot is available in the new chat menu
    | 'deprecated' // the bot is not available in the new chat menu, but existing conversations can be continued
    | 'archived' // conversations cannot continue and new chats cannot be created

export type ToolType = 'chatbot' | 'report_generator' | 'document_skimmer'
// Note that this should be called BotType for consistency with the codebase, but the backend uses ToolType
// For the users, we refer to all of these as tools, but some refactors on the BE to reflect this would be needed

export interface AiEngine {
    displayName: string
    region: string
    model: string
}

interface BaseBot {
    id: UUID
    name: string
    description: string
    supportsDocumentUpload?: boolean
    status: BotStatus
    toolType: ToolType
    isEditable: boolean
    responseMode: 'spool' | 'stream'
    classification: string
    aiEngines: AiEngine[]
}

interface Chatbot extends BaseBot {
    toolType: 'chatbot'
    supportsKnowledgeBase?: boolean
}

interface ReportBot extends BaseBot {
    toolType: 'report_generator'
    classification: 'GenericReportGenerator' | 'TranslationReportGenerator'
    supportsDocumentUpload: true
    supportsMultidocumentReport: boolean
    supportsFields: boolean
    supportsSessionConfig: boolean
}

interface SkimmerBot extends BaseBot {
    toolType: 'document_skimmer'
    supportsDocumentUpload: true
}

export type Bot = Chatbot | ReportBot | SkimmerBot

export interface Chat {
    id: UUID
    title: string | null
    bot: Bot
    latestTimestamp: string
    documentCount?: number
    status: ChatStatus
    progressUpdate: string | null
}

export interface ReportChat extends Chat {
    bot: ReportBot
}

export interface SkimmerChat extends Chat {
    bot: SkimmerBot
}

export const placeholderChatTitle = (botType: ToolType | null | undefined) => {
    switch (botType) {
        case 'chatbot':
            return 'New Chat'
        case 'report_generator':
            return 'New Report Generator'
        case 'document_skimmer':
            return 'New Table'
        default:
            return 'New Chat'
    }
}

interface BaseMessage {
    id: UUID
    chatId: UUID
    timestamp: string
    fromUser: boolean
    messageType: MessageType
}

export interface BotMessage extends BaseMessage {
    fromUser: false
    text: string
}

interface BaseUserMessage extends BaseMessage {
    fromUser: true
    // Backend will usually just send this as null. Frontend also handles it being undefined for simplicity in creating messages
    documents?: Document[] | null
}

export interface TextUserMessage extends BaseUserMessage {
    text: string
}

export interface DocumentUserMessage extends BaseUserMessage {
    text?: string
    documents: Document[]
}

export interface BaseColors {
    primary: string
    textOnSecondary: string
    descriptionText: string
    tertiary: string
    focus: string
    highlighted: string
    disabled: string
    disabledBackground: string
    background: string
    paper: string
    divider: string
    errorBackground: string
    error: string
}

export interface ThemeColors extends BaseColors {
    text: string
    secondary: string
}

export type UserMessage = TextUserMessage | DocumentUserMessage

export const isDocumentUserMessage = (message: UserMessage): message is DocumentUserMessage => !!message.documents

export type Message = BotMessage | UserMessage

export type ChatProgressMessage = string | null

export type DocumentUpdate = {
    documentId: UUID
    state: DocumentState
}

export interface Chunk {
    chatId: UUID
    messageId: UUID
    text: string
}

export const doNothing = () => {}
export const doNothingEventually = () => Promise.resolve()

const companies = ['Springbok', 'CharlesRussellSpeechlys', 'CharlesRussellSpeechlysUAT', 'Accor'] as const
export type Company = (typeof companies)[number]

export const isCompany = (value: unknown): value is Company => typeof value === 'string' && companies.includes(value as Company)

export const isDefined = <T>(value?: T): value is T => typeof value !== 'undefined'

export const isStringArray = (value: unknown): value is string[] => Array.isArray(value) && value.every(v => typeof v === 'string')
