import { useMsal } from '@azure/msal-react'
import { emphasize, styled, Typography, useTheme } from '@mui/material'
import { useDarkModeContext } from '../context/DarkModeContext'
import { useUserContext } from '../context/UserContext'
import CogIcon from '../icons/CogIcon'
import DarkModeIcon from '../icons/DarkModeIcon'
import LogoutIcon from '../icons/LogoutIcon'
import CenteredContainer from './common/CenteredContainer'
import UnstyledButton from './common/UnstyledButton'

const menuWidth = 200

const MenuContainer = styled(CenteredContainer)(({ theme }) => ({
    flexDirection: 'column',
    position: 'absolute',
    bottom: '50px',
    left: '46px',
    width: `${menuWidth}px`,
    height: 'auto',
    borderRadius: '22px',
    padding: '12px',
    backgroundColor: `${theme.palette.primary.main}`,

    [theme.breakpoints.down('sm')]: {
        margin: '0px 32px',
        left: '24px',
        borderRadius: '10px',
    },
}))

const MenuButton = styled(UnstyledButton)(({ theme }) => ({
    width: `${menuWidth}px`,
    height: '45px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingLeft: '5px',
    borderRadius: '12px',
    backgroundColor: `${theme.palette.primary.main}`,

    '&:hover': {
        backgroundColor: `${emphasize(theme.palette.primary.main, 0.05)}`,
        transition: 'background-color 0.3s',
    },

    [theme.breakpoints.down('sm')]: {
        width: '100%',
        borderRadius: '10px',
    },
}))

const MenuButtonText = styled(Typography)(({ theme }) => ({
    marginLeft: '10px',
    color: `${theme.palette.common.white}`,
}))

interface SettingsMenuProps {
    onAccountSettingsOpen: () => void
}

const SettingsMenu = ({ onAccountSettingsOpen }: SettingsMenuProps) => {
    const { palette } = useTheme()
    const { instance } = useMsal()
    const { isDarkMode, toggleDarkMode } = useDarkModeContext()
    const { isAccountManager } = useUserContext()

    const darkModeLabel = `${isDarkMode ? 'Light' : 'Dark'} Mode`

    return (
        <MenuContainer>
            {isAccountManager && (
                <MenuButton type='button' onClick={onAccountSettingsOpen} aria-label='Account Settings'>
                    <CogIcon color={palette.common.white} />
                    <MenuButtonText variant='h3'>Account Settings</MenuButtonText>
                </MenuButton>
            )}
            <MenuButton type='button' onClick={toggleDarkMode} aria-label={darkModeLabel}>
                <DarkModeIcon isDarkMode={isDarkMode} color={palette.common.white} />
                <MenuButtonText variant='h3'>{darkModeLabel}</MenuButtonText>
            </MenuButton>
            <MenuButton type='button' onClick={() => instance.logoutPopup()} aria-label='Logout'>
                <LogoutIcon color={palette.common.white} width={24} height={24} />
                <MenuButtonText variant='h3'>Logout</MenuButtonText>
            </MenuButton>
        </MenuContainer>
    )
}

export default SettingsMenu
