import { ListItem, styled, Typography } from '@mui/material'
import CrossIcon from '../../../icons/CrossIcon'
import TransparentButton from '../../common/TransparentButton'
import FileIcon from '../../../icons/FileIcon'
import Loading from '../../common/Loading'
import { UUID } from '../../../types'
import { KnowledgeBaseEntry } from './types'

const FlexBox = styled('span')({
    display: 'flex',
})

const DocListItem = styled(ListItem)({
    padding: '4px 0 4px 0',
    justifyContent: 'space-between',
})

const SizedLoading = styled(Loading)({
    width: '12px',
    height: '12px',
})

const DeleteButton = styled(TransparentButton)(() => ({
    marginLeft: '8px',
    borderRadius: '50%',
    width: '20px',
    height: '20px',
    padding: '5px',
}))

interface SourceDocumentUploadProps {
    entries: KnowledgeBaseEntry[]
    handleRemove: (id: UUID) => void
    loading: boolean
}

const SourceDocumentUploadList = ({ entries, handleRemove, loading }: SourceDocumentUploadProps) => {
    if (!entries.length) {
        return null
    }

    return (
        <>
            <Typography variant='h6'>Adding new files:</Typography>
            {entries.map(newEntry => (
                <DocListItem key={newEntry.id} className='visible-on-hover-parent'>
                    <FlexBox>
                        {loading ? <SizedLoading secondaryColor /> : <FileIcon width={16} />}
                        <Typography sx={{ ml: 1 }}>{newEntry.original.file.name}</Typography>
                        <DeleteButton
                            className='visible-on-hover'
                            type='button'
                            aria-label='remove document'
                            title='Remove document'
                            disabled={loading}
                            onClick={() => handleRemove(newEntry.id)}
                        >
                            <CrossIcon width={10} height={10} />
                        </DeleteButton>
                    </FlexBox>
                    <Typography>
                        {newEntry.processed ? `Processed file provided: ${newEntry.processed.file.name}` : 'Original to be processed by system'}
                    </Typography>
                </DocListItem>
            ))}
        </>
    )
}

export default SourceDocumentUploadList
