import styled from 'styled-components'
import UnstyledButton from './UnstyledButton'

const TextButton = styled(UnstyledButton)`
    color: ${props => props.theme.colors.text};
    text-decoration: underline;
    font-size: 14px;
`

export default TextButton
