import { ListItem, styled, Typography } from '@mui/material'
import { useState } from 'react'
import { useUserContext } from '../../../context/UserContext'
import { knowledgeBaseEndpoint } from '../../../endpoints'
import { useDelete } from '../../../hooks/useDelete'
import CrossIcon from '../../../icons/CrossIcon'
import ExportIcon from '../../../icons/ExportIcon'
import FileIcon from '../../../icons/FileIcon'
import { isDownloadableState, isLoadingState, SourceDocument, UUID } from '../../../types'
import { forwardPropsWithTransient } from '../../../utils/muiUtils'
import Loading from '../../common/Loading'
import DeleteTooltip from '../../common/tooltips/DeleteTooltip'
import TransparentButton from '../../common/TransparentButton'

const FlexBox = styled('span')({
    display: 'flex',
})

const DocListItem = styled(ListItem)({
    padding: '4px 0 4px 0',
    justifyContent: 'space-between',
})

const SourceDocumentTitle = styled(Typography, { shouldForwardProp: forwardPropsWithTransient })<{ $toDelete: boolean }>(({ $toDelete }) => ({
    marginLeft: 8,
    textDecoration: $toDelete ? 'line-through' : 'initial',
}))

const DeleteButton = styled(TransparentButton)(() => ({
    marginLeft: '8px',
    borderRadius: '50%',
    width: '20px',
    height: '20px',
    padding: '5px',
}))

const SizedLoading = styled(Loading)({
    width: '12px',
    height: '12px',
})

interface SourceDocumentListItemProps {
    sourceDoc: SourceDocument
    botId: UUID
    onDeleteSuccess: () => void
    loading: boolean
}

const SourceDocumentListItem = ({ sourceDoc, botId, onDeleteSuccess, loading }: SourceDocumentListItemProps) => {
    const { isSpringbokUser, isMaintainer } = useUserContext()

    const [deleteTooltipOpen, setDeleteTooltipOpen] = useState(false)

    const [deleteSourceDocument, deleting] = useDelete(knowledgeBaseEndpoint(botId))

    const handleDelete = async () => {
        const success = await deleteSourceDocument(sourceDoc.id)
        success && onDeleteSuccess()
    }

    const processing = loading || isLoadingState(sourceDoc.state) || (sourceDoc.state === 'extracted' && !sourceDoc.finishedEmbedding)

    return (
        <DeleteTooltip
            id={sourceDoc.id}
            targetName={sourceDoc.name}
            onDelete={handleDelete}
            open={deleteTooltipOpen}
            handleClose={() => setDeleteTooltipOpen(false)}
        >
            <DocListItem key={sourceDoc.id} className='visible-on-hover-parent'>
                <FlexBox>
                    <FileIcon width={16} />
                    <SourceDocumentTitle $toDelete={deleting} color={sourceDoc.state === 'error' ? 'error' : 'textPrimary'}>
                        {sourceDoc.name}
                    </SourceDocumentTitle>
                    {isSpringbokUser && isMaintainer && (
                        <DeleteButton
                            className='visible-on-hover'
                            type='button'
                            aria-label='remove document'
                            title='Delete from knowledge base'
                            disabled={isLoadingState(sourceDoc.state)}
                            onClick={() => setDeleteTooltipOpen(true)}
                        >
                            <CrossIcon width={10} height={10} />
                        </DeleteButton>
                    )}
                </FlexBox>
                {processing ? (
                    <FlexBox sx={{ gap: 2 }}>
                        <SizedLoading secondaryColor />
                        <Typography>Processing...</Typography>
                    </FlexBox>
                ) : (
                    <>
                        {isSpringbokUser && (
                            <TransparentButton disabled={!isDownloadableState(sourceDoc.state)}>
                                <ExportIcon width={16} height={16} />
                                Download
                            </TransparentButton>
                        )}
                    </>
                )}
            </DocListItem>
        </DeleteTooltip>
    )
}

export default SourceDocumentListItem
