import { allowedFileExtensions, getExtension, isJson } from '../../../utils/documents'
import { generateId } from '../../../utils/objectUtils'
import { KnowledgeBaseEntry } from './types'

// File types that are allowed if a manually processed copy is also provided
const restrictedKnowledgeBaseExtensions = ['pptx', 'json']
export const allowedKnowledgeBaseExtensions = allowedFileExtensions.concat(restrictedKnowledgeBaseExtensions)

const isRestrictedFileType = (file: File) => {
    const extension = getExtension(file)
    return !!extension && restrictedKnowledgeBaseExtensions.includes(extension)
}

/**
 * Pairs original and processed knowledge base files to be ingested into the system.
 * File pairs MUST have the same file name. With the processed file being .json.
 *
 * Original files that only need OCR processing can be provided without their processed counterpart.
 */
export const pairInputFiles = (files: File[]) => {
    const originalFiles = new Map<string, File>()
    const processedFiles = new Map<string, File>()

    files.forEach(file => {
        const nameWithoutExtension = file.name.substring(0, file.name.lastIndexOf('.'))

        if (isJson(file)) {
            processedFiles.set(nameWithoutExtension, file)
        } else {
            originalFiles.set(nameWithoutExtension, file)
        }
    })

    const entries: KnowledgeBaseEntry[] = []
    originalFiles.forEach((original, key) => {
        const processed = processedFiles.get(key)
        const requiresProcessed = isRestrictedFileType(original)

        if (!requiresProcessed || (requiresProcessed && processed)) {
            entries.push({
                id: generateId(),
                name: key,
                original: { id: generateId(), file: original },
                processed: processed ? { id: generateId(), file: processed } : undefined,
            })
        }
    })

    return entries
}

export const validateKnowledgeBaseFiles = (files: File[]) =>
    files.filter(file => {
        const extension = getExtension(file)

        return extension && file.size > 0 && allowedKnowledgeBaseExtensions.includes(extension)
    })
